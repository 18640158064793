import React from 'react'

import { ViewportProvider } from './ViewportContext'
import { ThemeProvider } from './ThemeContext'
import { LanguageProvider } from './LanguageContext'

const RootProvider = ({ children }) => {
  return (
    <ViewportProvider>
      <ThemeProvider>
        <LanguageProvider>
          {children}
        </LanguageProvider>
      </ThemeProvider>
    </ViewportProvider>
  );
}

export { RootProvider }
