import React from "react"
import { reduceLanguages } from '../utils'

const languageContext = React.createContext({});

class LanguageProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = { userLanguages: ["en"] };
  }

  componentDidMount() {
    this.setState({ 
      userLanguages: reduceLanguages(
        window?.navigator?.languages || ["en"]
      )
    })
  }

  render() {
    const { children } = this.props
    const { userLanguages } = this.state
    
    return (
      <languageContext.Provider
        value={{
          userLanguages: userLanguages,
        }}
      >
        {children}
      </languageContext.Provider>
    )
  }
}

export { LanguageProvider, languageContext }
