import { strict as assert } from 'assert';

function pathToSeoTitle(path: string): string {
  return path.split("/")
    .filter(e => e!=="")
    .map(e => e.charAt(0).toUpperCase() + e.slice(1))
    .join("/");
}

function reduceLanguages(languages: string[]): string[] {
  try {
    return languages.flatMap((lang: string) => {
        if (lang.length >= 2) 
          return lang.slice(0, 2);
        }
      ).reduce((langs: string[], current: string) => {
        if (!langs.includes(current)) {
          langs.push(current)
        }
        return langs;
      }, []);
  } catch (e) {
    // console.log("Oops error in language retrieval: " + e)
    return [];
  }
}

interface Frontmatter {
  title?: string,
  date?: string,
  lang?: string,
  description?: string
}
interface Node { frontmatter: Frontmatter }

function orderPostsByUserLanguages(nodes: Node[], userLanguages: string[]): [string, Node[]][] {

  assert.ok(nodes, "Nodes should not be null");
  assert.ok(userLanguages, "User languages should be provided");
  assert.ok(userLanguages.length, "At least one language should be provided");

  nodes.forEach((node) => {
    assert.ok(node.frontmatter, "Frontmatter should not be undefined");
    assert.ok(node.frontmatter.date, "Date in frontmatter should not be undefined");
    assert.ok(node.frontmatter.lang, "Date in frontmatter should not be undefined");
  });

  // map from language to posts, with posts sorted by date
  const dictLang2Posts: [string, Node[]][] = nodes
    .sort((n1, n2) => new Date(n2.frontmatter.date).getTime() - new Date(n1.frontmatter.date).getTime())
    .reduce((map, curr) => {
      (map[curr.frontmatter.lang] = map[curr.frontmatter.lang] || []).push(curr);
      return map;
    }, []);

  // console.log("dictLang2Posts", JSON.stringify(dictLang2Posts, null, 2));

  // lists of languages in the posts, ordered by the number of posts
  const listPostsLanguages = Object.entries(dictLang2Posts)
    .sort(([lang1, posts1], [lang2, posts2]) => posts2.length - posts1.length)
    .map(([lang, posts]) => lang);

  // console.log("listPostsLanguages", listPostsLanguages);

  // list of user languages that exist in posts, ordered by user preference
  const listUserLanguagesSupported = 
    userLanguages.filter((ulang) => {
      return listPostsLanguages.includes(ulang);
    });

  // console.log("listUserLanguagesSupported", listUserLanguagesSupported);

  const listPostsLanguagesUnsupported = listPostsLanguages
    .filter((plang) => {
      return !listUserLanguagesSupported.includes(plang);
    });

  // console.log("listPostsLanguagesUnsupported", listPostsLanguagesUnsupported);

  const listPostsThatUserUnderstands: [string, Node[]][] = 
    listUserLanguagesSupported
      .map((ulang) => [ulang, dictLang2Posts[ulang]])

  // console.log("listPostsThatUserUnderstands", JSON.stringify(listPostsThatUserUnderstands, null, 2));

  const listPostsThatUserDoesntUnderstand : [string, Node[]][] =
    listPostsLanguagesUnsupported
      .map((plang) => [plang, dictLang2Posts[plang]])
  
  // console.log("listPostsThatUserDoesntUnderstand", JSON.stringify(listPostsThatUserDoesntUnderstand, null, 2));

  const listPosts: [string, Node[]][] =  listPostsThatUserUnderstands.concat(listPostsThatUserDoesntUnderstand)
    
  
  // console.log("listPosts", JSON.stringify([...listPosts], null, 2));

  return listPosts;
}

export { pathToSeoTitle, reduceLanguages, orderPostsByUserLanguages }
